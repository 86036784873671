.accordion {
    padding: 25px 10px;
    margin: 10px 0;
    width: 1100px;
    height: auto;
    border-radius: 15px;
    background:  #FFFFFF;
    border-bottom: 1px solid #A3A3A3;
    cursor: pointer;
}

.expand {
    background:  #FFFFFF;
    border-bottom: 1px solid #A3A3A3;
    border-radius: 16px;
    padding: 25px 10px;
    /* margin: 0; */
}
.faq .accordion-content p.expand{
margin-top: 2px;
}

.not-expand{
background-color: transparent;
border: none;
}

p.faq-p {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0em;
    text-align: left;
    color: #051727;
}

.faq .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.faq .faq-list .accordion-header p {
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0;
    color: #051727;
    margin: 0 10px;
    text-align: left;
}
.faq .faq-list .accordion-header.expand p {
    color: #28a745;
}

.faq .accordion-header .faq-icons {
    font-size: 35px;
    color: #28a745;
}

.faq .accordion-content p {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0em;
    color: #051727;
    text-align: justify;
    margin-top: 20px;
}


@media screen and (max-width: 1024px) {
   
    .accordion {
        width: 900px;
    }
}
@media screen and (max-width: 882px) {
    
    .accordion {
        width: 750px;
    }
}
@media screen and (max-width: 769px) {
    
    .accordion {
        width: 620px;
    }
}

@media screen and (max-width: 600px) {
    .accordion {
        width: 545px;
    }
    .faq .faq-list .accordion-header p {
        font-size: 20px;
    }
}
@media screen and (max-width: 426px) {
    .accordion {
        width: 365px;
    }
    .faq .faq-list .accordion-header p {
        font-size: 18px;
    }
    .faq .accordion-header .faq-icons {
        font-size: 25px;
    }
}
@media screen and (max-width: 376px) {
    .accordion {
        width: 325px;
    }
}
@media screen and (max-width: 321px) {
    .accordion {
        width: 280px;
    }
  
}