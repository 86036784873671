
@import url('https://fonts.cdnfonts.com/css/poppins');


*,
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body,
html {

                            
  font-family: 'Poppins', sans-serif;
                                                
                
  overflow-x: hidden;

}
ul{
  padding: 0;
}