header {
    padding: 20px 80px;

}


.header {
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 999;
}

.nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-bar h1 {
    font-size: 25px;
    font-weight: 800;
    line-height: normal;
    color: #17191C;
    margin-bottom: 0;
    white-space: nowrap;
}

.nav-bar h1 span {
    color: #56AC48;
}

.navbar-link {
    list-style: none;
    justify-content: space-around;
    display: flex;
    padding: 0;
    margin: 0;
}

.navbar-link li {
    text-decoration: none;
    margin: 0 20px;
}

.navbar-link li a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #051727;
    cursor: pointer;
    white-space: nowrap;

}

.navbar-link li a .iconify {
    font-size: 25px;
    color: #56AC48;
}

.navbar-link li a:hover {
    color: #56AC48;
}

.menu-icon {
    font-size: 30px;
    color: #56AC48;
    cursor: pointer;
    display: none;
    /* Initially hidden on larger screens */
    /* position: absolute;
  top: 20px;
  right: 20px; */
    /* z-index: 1001; */
}








.landing-page .Hero-Section h1,
h1.how,
.explore-section h1,
.story-section h1,
.faq-section h1,
h1.how,
.Hero-Section h1 span.not-span {
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    color: #051727;
    font-family: 'Poppins', sans-serif;
    white-space: nowrap;
}

.landing-page .Hero-Section h1 {

    text-align: center;

}

.landing-page .star-icon {
    font-size: 40px;
    color: #2F7823;
    margin-bottom: 30px;
}

.landing-page h1 span,
h1.how span,
.story-section h1 span {
    color: #56AC48;
}

.star-img {
    width: 80px;
}

.Hero-Section .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 150px;
}

.Hero-Section .hero-text {
    flex: 2;
}

.landing-page p.description {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    color: #051727;
    width: 600px;
    text-align: center;
    opacity: .8;

}





.landing-page-button .btn {
    width: auto;
    padding: 10px;
    border-radius: 10px;
    background: #ffffff;
    border: 2px solid #56AC48;
    color: #56AC48;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    margin: 0 15px;
}


.Hero-Section .landing-page-button .btn.buy:hover {

    background: #56AC48;
    line-height: normal;

    color: #FFFFFF;

}


/* ImageCarousel.css */



.custom-carousel .div-image {
    width: 85%;
    border-radius: 30px;


}

.custom-carousel .big-image.gallery .div-image {
    border: 10px solid rgba(86, 172, 72, 0.4);
}


.landing-page .carousel-indicators {

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: -2rem;
    margin-left: 15%;
}

.carousel-item {
    opacity: 0;
    transition: opacity 4s !important;
}

.carousel-item.active {
    opacity: 1;
    transition: opacity 4s !important;
}

.landing-page .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 60px;
    height: 5px;
    padding: 0;
    margin: 0 10px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #56AC48;
    opacity: .5;
    transition: opacity .6s ease;
    border-radius: 5px;
}

.landing-page .carousel-indicators .active {
    opacity: 1;
    background-color: #56AC48;
}

.how-image {
    width: 500px;

    overflow: hidden;
    height: fit-content;

}

.how-image img {
    width: 100%;
    object-fit: contain;
    height: 80%;
    border-radius: 32px;
}




.card-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-section p {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;

}

.card-section p span {
    color: #56AC48;
}



.card-section h6 {
    font-size: 28px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
    text-align: left;
    white-space: normal;
    width: 500px;

}

.card-section h2 {}


.membership-call {
    width: 1100px;
    margin: 0 auto;
    border-radius: 32px;
    box-shadow: 0px 4px 4px 0px #F7F7F7;
    position: relative;
}

.membership-call .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url('../../assets/rectangle.webp');
    height: 500px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0 30px;
}

.membership-call .text img.member {
    width: 65%;
    position: absolute;
    bottom: 0;
    object-fit: cover;
    z-index: 1;
    right: 50px;
}

.membership-call .text .text-content {
    text-align: left;
    float: left;
}

.arrow-image {
    width: 30%;
    bottom: 0;
    object-fit: contain;
    margin-left: 30px;
}

.membership-call .text h6 {
    font-size: 28px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    color: #212121;
    text-align: left;
}

.membership-call .text h6 span {

    font-weight: 700;
    color: #56AC48;
}


.membership-call .text p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: normal;
    color: #56AC48;
    /* width: 400px; */
    text-align: left;
    line-height: 30px;
    opacity: .8;
}

.membership-call .landing-page-button a,
.card-section .landing-page-button a {
    background-color: #56AC48;
    color: #FFFFFF;
    margin: 0;
}

.membership-call .landing-page-button a:hover,
.card-section .landing-page-button a:hover {
    background: #ffffff;
    border: 2px solid #56AC48;
    color: #56AC48;
}


.membership-call .text p a {
    color: #56AC48;
}


.steps {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #2F7823;

}

.steps hr {
    height: 0;
    width: 200px;
    margin: 10px 0;
    border: 1px solid #000000;
    margin: 0 30px;

}


.arrow-outward {
    background-color: #2F7823;
    border-radius: 50%;
    color: #fff;
}


.faq-section {
    background: linear-gradient(148.72deg, rgba(86, 172, 72, 0.06) 41.69%, rgba(87, 164, 96, 0.06) 68.38%, rgba(93, 112, 249, 0.06) 169.51%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);

}

.founder {
    font-weight: 700;
    font-style: italic;
}

.join {
    font-weight: 700;

}

.become {
    width: 1100px;
    height: 500px;
    border-radius: 32px;
    background-image: url('../../assets/slide1.webp');
    /* Replace with your image URL */
    background-size: cover;
    position: relative;
}

.become::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black overlay with 50% opacity */
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 32px;
}

.become .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

.become h1,
.become h1 span {
    font-size: 38px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 30px;

}

.become a {
    text-decoration: none;
    width: 150px;
    padding: 15px 20px;
    border-radius: 10px;
    background: #56AC48;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    color: #FFFFFF;
}

.become a:hover {
    background: #ffffff;
    border: 2px solid #56AC48;
    line-height: normal;
    color: #56AC48;
}











.footer a {
    text-decoration: none;
}

.footer-links {
    padding: 20px 80px;
}

.company-links h1 {
    font-size: 50px;
    font-weight: 800;
    line-height: normal;
    color: #051727;
    margin-bottom: 0;
    white-space: nowrap;
}

.company-links h1 span {
    color: #56AC48;
}

.footer ul li {
    list-style: none;
    margin: 10px 0;
}


.footer .email-link a {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #051727;
    white-space: nowrap;

}

.footer li a:hover,
.footer a:hover {
    color: #56AC48;
}

.footer h5 {
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    color: #051727;
    margin-bottom: 10px;
}

.footer-newsletter .search-input {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    border-radius: 32px;
    border: 1px solid;
}



.search-input .search-icon {
    font-size: 26px;
}

.footer .social-media-links .iconify {
    font-size: 35px;
    margin: 0 15px;
    color: #56AC48;
}

.email-icon,
.phone-icon {
    font-size: 25px;
    margin-right: 10px;
}

.footer .copyright h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #051727;
    white-space: nowrap;
    display: flex;
    justify-content: space-around;
}

.footer hr {
    /* width: 90%; */
    border: 1px solid #4f4f4f
}

.footer .copyright h6 p {
    font-weight: 700;
}

.footer .copyright h6 p span {
    color: #56AC48;
}













@media screen and (max-width: 1024px) {

    .Hero-Section .title {
        margin-top: 50px;
    }

    .landing-page .Hero-Section h1,
    h1.how,
    .explore-section h1,
    .story-section h1,
    .faq-section h1,
    h1.how,
    .company-links h1 {
        font-size: 45px;
    }

    .how-image {
        width: 400px;

    }

    .card-section h6 {
        font-size: 28px;
        width: 400px;
    }

    .membership-call {
        width: 915px;
    }

    .membership-call .text {
        height: 400px;
    }

    .membership-call .text p {
        width: 300px;
    }

    .membership-call .text img.member {
        width: 60%;
    }

    .story-section.mx-5,
    .faq-section .mx-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }

    .become {
        width: 915px;
        height: 400px;
    }

    .become h1,
    .become h1 span {
        font-size: 32px;
    }
}

@media screen and (max-width: 992px) {
    .menu-icon {
        display: block;
    }

    .navbar-link {

        display: none;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #F7F7F7;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* z-index: 1000; */
        padding: 0;


    }

    .mobile-menu-open .navbar-link {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navbar-link li {
        margin: 40px auto;
    }

    .navbar-link li a {
        font-size: 30px;
    }

    .how.mx-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }

    .how-image img {
        width: 95%;
    }

    .become {
        width: 850px;
    }
}

@media screen and (max-width: 882px) {

    .landing-page .Hero-Section h1,
    h1.how,
    .explore-section h1,
    .story-section h1,
    .faq-section h1,
    h1.how,
    .company-links h1 {
        font-size: 38px;
    }


    .howpx.px-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }


    .how-image {
        width: 350px;
    }

    .card-section h6 {
        font-size: 26px;
    }

    .membership-call {
        width: 800px;
    }

    .membership-call .text {
        height: 350px;
    }

    .membership-call .text img.member {
        width: 60%;
    }

    .become {
        width: 735px;
    }

    .become h1,
    .become h1 span {
        font-size: 28px;
    }

    .footer .social-media-links .iconify {
        font-size: 25px;
        margin: 0 5px;
    }
}

@media screen and (max-width: 769px) {

    header,
    .footer-links {
        padding: 20px 45px;
    }

    .landing-page .Hero-Section h1,
    h1.how,
    .explore-section h1,
    .story-section h1,
    .faq-section h1,
    h1.how,
    .company-links h1 {
        font-size: 35px;
    }

    .how.mx-5 {
        margin: 0 !important;
    }

    .star-img {
        width: 60px;
    }

    .landing-page p.description {
        width: 550px;
    }

    .how-image {
        display: none;
    }



    .membership-call {
        width: 700px;
    }

    .membership-call .text p {
        width: 300px;
    }

    .membership-call .text h6 {
        width: 320px;
    }

    .arrow-image {
        width: 30%;
    }

    .membership-call .text {
        height: 300px;
    }


    .testimonial-text {
        font-size: 16px;
    }

    .become {
        width: 620px;
        height: 260px;
        background-size: contain;
    }

    .become h1,
    .become h1 span {
        font-size: 24px;
        font-weight: 600;
        line-height: 40px;
    }

}

@media screen and (max-width: 600px) {
    .Hero-Section .title {
        margin-top: 40px;
    }

    .landing-page p.description {
        width: 500px;
        margin: 5px auto !important;
    }

    .landing-page .Hero-Section h1,
    h1.how,
    .explore-section h1,
    .story-section h1,
    .faq-section h1,
    h1.how,
    .company-links h1 {
        font-size: 30px;
    }

    .star-img {
        display: none;

    }

    .card-section h6,
    .membership-call .text h6 {
        font-size: 22px;
    }

    .membership-call .text h6 {
        width: 250px;
    }

    .card-section p {
        font-size: 14px;
    }


    .card-section li p {
        font-size: 16px;
    }

    .membership-call {
        width: 550px;
    }

    .membership-call .text {
        height: 230px;
    }

    .membership-call .text img.member {
        width: 65%;
        right: 0;
    }

    .membership-call .text .landing-page-button img {
        display: none;
    }

    .story-section.p-5,
    .faq-section .px-5 {
        padding: 0 !important;
    }

    .become {
        width: 515px;
        height: 200px;
    }

    .become h1,
    .become h1 span {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
    }

    .become a {
        width: auto;
        padding: 10px;
        font-size: 16px;
    }

    header,
    .footer-links {
        padding: 20px 30px;
    }

    .company-links h1 {
        font-size: 24px;
    }

    .email-icon,
    .phone-icon {
        margin-right: 0px;
    }

}

@media screen and (max-width: 426px) {
    header {
        padding: 20px 15px;
    }

    .nav-bar h1 {
        font-size: 20px;
    }

    .navbar-link li {
        margin: 20px auto;
    }

    .navbar-link li a {
        font-size: 18px;
    }

    .landing-page .Hero-Section h1,
    h1.how,
    .explore-section h1,
    .story-section h1,
    .faq-section h1,
    h1.how,
    .company-links h1 {
        font-size: 26px;
    }
    .Hero-Section .title {
        margin-top: 20px;
    }
    .landing-page p.description {
        width: 400px;
    }
    .custom-carousel .div-image {
        width: 90%;
    
    }
    .how.mx-5,
    .howpx.px-5 {
        padding: 0 25px !important;
    }

    .howpx.mx-4 {
        margin-right: 0rem !important;
        margin-left: 0rem !important;
    }

    .card-section h6 {
        width: 370px;
    }

    .card-section h6,
    .membership-call .text h6 {
        font-size: 18px;
        line-height: normal;
    }

    .membership-call .text h6 {
        /* line-height: normal; */
        width: 210px;
    }

    .membership-call .text {
        height: 155px;
        padding: 0 10px;
        /* background-image: none */
    }

    .membership-call .text img.member {
        width: 60%;
        right: -15px;

        /* display: none; */
    }

    .membership-call .text p {
        line-height: normal;
        width: 240px;
    }
    .membership-call {
        width: 395px;
    }

    .become {
        width: 355px;
    }

    .become h1,
    .become h1 span {
        font-size: 14px;
    }

    .company-links {
        flex-direction: column;
    }

    .footer-links {
        padding: 20px 15px;
    }

}

@media screen and (max-width: 376px) {
    .landing-page p.description {
        width: 330px;
    }

    .star-img {
        width: 60px;
    }

    .landing-page .Hero-Section h1,
    h1.how,
    .explore-section h1,
    .story-section h1,
    .faq-section h1,
    h1.how,
    .company-links h1 {
        font-size: 24px;
    }

    a.button-buyer,
    a.button-buyer:hover {

        /* display: none; */
    }

    .become {
        width: 320px;
    }

    .become h1,
    .become h1 span {
        width: 295px;
        white-space: break-spaces;
        margin: 0 auto 30px auto;
    }
    .membership-call .text {
    
        background-position: 180px;
        padding: 0 15px;
    }
    .membership-call .text img.member {
        width: 56%;
        right: 10px;
    }
    .membership-call .text p {
        /* width: 320px; */
    }

    .card-section h6 {
        width: 300px;
    }
    /* a.button-buyer, a.button-buyer:hover {
        width: auto;
    } */
}

@media screen and (max-width: 321px) {

    .landing-page .Hero-Section h1,
    h1.how,
    .explore-section h1,
    .story-section h1,
    .faq-section h1,
    h1.how,
    .company-links h1 {
        font-size: 22px;
    }

    .landing-page p.description {
        width: 272px;
        text-align: justify;
        font-size: 16px;
    }

    .card-section li p {
        font-size: 14px;
    }

    .become {
        width: 280px;
    }

    .become h1,
    .become h1 span {
        width: 250px;
    }

    .membership-call .text p {
        font-size: 13px;
        width: 160px;

    }
    .membership-call .text img.member {
        width: 55%;
        right: 68px;
    }
    .membership-call .text {
        background-position: 105px;
        height: 170px;
        padding: 0 22px;
    }
   
    .card-section h6 {
        width: auto;
    }
    .membership-call .text h6 {
        width: 226px;
    }
}

@media screen and (max-width: 567px) {}