.how-list li {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 25px 0;
}

.how-list li {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;

}

.how-list .iconify {
  color: #56AC48;
  height: 30px;
  min-width: 30px;
  margin-right: 20px;
}



.custom-carousel .overlay {
  border-radius: 30px;
  display: flex;
  /* width: 80%;  */
  height: fit-content;

}

/* Image inside the carousel */
.story.custom-carousel .div-image {
  width: 100%;
  height: 80%;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000B2;
  padding: 10px 0;
  /* Adjust padding as needed */
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;


}

/* Overlay */
.story.custom-carousel .story-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

/* Testimonial Text */
.testimonial-text {
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 10px;
}

.testimonial-text p.mobile-text {
  display: none;
}

a.button-buyer,
a.button-buyer:hover {
  text-decoration: none;
  width: 700px;
  padding: 15px 30px;
  /* Adjust padding as needed */
  border-radius: 32px;
  background-color: #56AC48;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}

.button-buyer:hover {
  color: inherit;
}



@media screen and (max-width: 1024px) {

  .how-list li {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
}

@media screen and (max-width: 769px) {

  a.button-buyer,
  a.button-buyer:hover {
    width: auto;
    font-size: 16px;
  }

}

@media screen and (max-width: 600px) {

  a.button-buyer,
  a.button-buyer:hover {
    width: auto;
    font-size: 14px;
    padding: 5px 15px;
  }

  .testimonial-text {
    font-size: 14px;
    font-weight: normal;
  }
}

@media screen and (max-width: 426px) {
  .testimonial-text p.mobile-text{
    display: block;
     font-size: 14px;
    font-weight: normal;
    margin-bottom: 0px;
    color: #000;
  }

  .testimonial-text p.desktop-text {
    display: none;
   
  }

  .custom-carousel .overlay {
    position: relative;
    background: transparent;

  }

  .carousel-item {
    height: max-content;
    padding: 10px 0;
  }

  .custom-carousel.story .carousel-inner {
    height: auto;
  }

  a.button-buyer,
  a.button-buyer:hover {
    width: auto;
  }

  .how-list .iconify {
    height: 20px;
    min-width: 20px;
  }
}

@media screen and (max-width: 321px) {
  .how-list .iconify {
    /* font-size: 18px; */
  }

  .testimonial-text p {
    font-size: 12px;
  }
}